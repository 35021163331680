.tab-headingbar {
    border-bottom: 1px solid #DDDDDD;
    position: relative;
    display: flex;
    justify-content: space-between;
}

.tab-headingbar h2 {
    line-height: 30px;
}

.subscription-group-heading {
    padding: 34px 0 32px;
}

.add-btn {
    background-color: transparent;
    padding: 0;
    border: 0;
}

.product-feed-section {
    padding: 34px 0 0;
}

.product-feed-heading {
    margin-bottom: 26px;
}

.row-custom {
    margin: 0 -4px;
}

.product-feed-inner {
    /* overflow-y: overlay;
    height: calc(100vh - 562px); */
    position: relative;
    /* padding-bottom: 15px; */
}

.product-feed-inner::-webkit-scrollbar {
    width: 5px;
    position: absolute;
    display: none;
    
}

.product-feed-inner::-webkit-scrollbar-track {
    box-shadow: none;
    background-color: transparent;
}

.product-feed-inner::-webkit-scrollbar-thumb {
    background-color: rgba(221, 221, 221, 0.5);
    outline: none;
    border-radius: 5px;
    
}

.product-feed-inner:hover::-webkit-scrollbar {
    display: block;
}

.search-boxinput {
    border: 2px solid #c9c9c9;
    padding: 10px 16px;
    border-radius: 6px;
    margin: 20px 0;
    width: 100%;
    outline: 0;
}

.row-spacebtwn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0;
}

.subscription-group-img {
    height: 50px;
    margin-right: 10px;
    border-radius: 50%;
}

.subscription-group-name {
    font-size: 16px;
}

.subscription-group-category {
    color: #a0a0a0;
    font-size: 12px;
}

.hasborder {
    border: 1px solid blue
}

.modal-save-btn {
    box-shadow: none !important;
    margin-top: 20px !important;
}

.groups-container {
    height: auto;
    overflow-y: scroll;
    max-height: 350px;
    padding-right: 10px;
}

.groups-container::-webkit-scrollbar {
     width: 4px;
}

.groups-container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: white;
}

.groups-container::-webkit-scrollbar-thumb {
    background-color: #4B85DC;
    border-radius: 10px;
}

.noGroupsTxt {
    text-align: center;
    margin: 60px;
    font-size: 18px;
    color: gray;
}

.error-text {
    color: red;
    margin-top: 16px;
    text-align: center;
}