@import "src/styles/variables";

.order-wrapper {
  padding: 12px 0;
  border-bottom: $gray-border;
}


.order-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.order-main-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.order-main-info {
  @media (max-width: $tablet-screen) {
    width: 155px;
  }
}

.order-img img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 8px;

  @media (max-width: $tablet-screen) {
    width: 56px;
    height: 56px;
  }
}

.order-id {
  color: $grey;
  font-size: 16px;
  font-weight: 600;
  width: 160px;

  @media (max-width: $tablet-screen) {
    font-size: 14px;
    width: auto;
  }
}

.order-product-name {
  font-weight: 600;
  width: 400px;

  @media (max-width: $tablet-screen) {
    font-size: 14px;
    width: auto;
  }
}

.order-seller-name {
  color: $blue;
  font-weight: 300;
  font-size: 14px;

  @media (max-width: $tablet-screen) {
    font-size: 12px;
  }
}

.order-status-wrapper {
  display: flex;
  align-items: center;
}

.order-status {

  @media (max-width: $tablet-screen) {
    font-size: 14px;
  }
}

.order-payment-status {
  color: $grey;
  font-size: 14px;
  font-weight: 300;

  @media (max-width: $tablet-screen) {
    font-size: 12px;
  }
}

.order-statuses-and-price {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.order-price {
  color: $grey;
  font-size: 16px;
  font-weight: 600;
}

.order-triangular-arrow {
  cursor: pointer;
}

.order-triangular-arrow-up svg {
  transform: rotate(180deg);
}