@import "src/styles/variables";

.tab-header {
  display: flex;
  border-top: 1px solid #dddddd;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
}
.tab-header .tab-header_title h4 {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #282323;
  padding-top: 16px;
}

.tab-header .tab-header_title h5 {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #ea3535;
  padding-top: 16px;
}

.tab-header .tab-header_title p {
  font-weight: 500;
  font-size: 20px;
  line-height: normal;
  color: #282323;
  padding-top: 16px;
}
.info-button-group {
  align-items: center;
  background-color: transparent;
  padding: 16px 24px;
  transition: 0.3s;
  border-radius: 32px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  transition: 0.3s;
}
.info-button-group:active {
  transform: translateY(4px);
}
.info-button-group.cancel-button {
  border: 2px solid #ea3535;
  color: #ea3535;
}

.info-button-group.cancel-button.mobile {
  display: none;
  width: 100%;
}
.info-button-group.cancel-button:hover {
  color: #ffffff;
  background-color: #ea3535;
}
.info-button-group.cancel-button:active {
  transform: translateY(4px);
}
.tab-body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  border-bottom: 1px solid #dddddd;
}
.order-tab .column-info {
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.03em;
  padding: 16px 0;
  color: #000000;
  border-bottom: 1px solid #dddddd;
  img {
    max-width: 100%;
    max-height: 300px;
    margin: 0 auto;
    display: block;
    text-align: center;
  }
}
.order-tab .column-info:last-child {
  border: none;
}

.order-tab .column-title {
  font-weight: 600;
  font-size: 14px;
  padding: 14px 0;
  line-height: 17px;
  text-align: left;
  letter-spacing: 0.03em;
  color: #000000;
  border-bottom: 1px solid #dddddd;
}
.order-tab .column-history-title {
  display: flex;
  padding: 2px 0;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.order-params {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 16px;
}
.order-param p:first-child {
  border-bottom: 1px solid #dddddd;
}
.order-color-icon {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: inline-block;
  position: absolute;
  top: 50%;
  margin-left: 6px;
  transform: translateY(-50%);
}
.order-param p {
  position: relative;
  padding: 12px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.03em;
  color: #000000;
}
.open-order-history {
  color: #4b85dc;
  background: transparent;
  font-size: 14px;
  font-weight: 700;
  transition: 0.3s;
  padding: 10px 24px;
  border-radius: 20px;
  border: 2px solid #4b85dc;
}
.open-order-history:active {
  transform: translateY(4px);
}
.open-order-history:hover {
  color: #ffffff;
  background-color: #4b85dc;
}
@media (max-width: 690px) {
  .tab-header {
    padding-top: 8px;
    padding-bottom: 12px;
  }
  .tab-body {
    grid-template-columns: 1fr;
  }
  .tab-header h4 {
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    padding-top: 0;
  }
}
@media (max-width: $mobile-screen) {
  .order-tab .column-history-title {
    padding-bottom: 6px;
  }
  .tab-header {
    flex-direction: column;
  }
  .info-button-group.cancel-button {
    display: none;
  }
  .info-button-group.cancel-button.mobile {
    display: block;
    margin-top: 15px;
  }
  .open-order-history {
    width: 100%;
    margin: 12px 0 6px;
  }
  .order-tab .column-info {
    padding: 12px 0;
  }
}
