@import "src/styles/variables";

.cancel-modal-container {
  width: 100%;
}
.cancel-modal-container p {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.03em;
  color: #000000;
  margin-bottom: 16px;
}
.cancel-input-wrapper {
  position: relative;
  margin-bottom: 40px;
}
.cancel-modal-container .error-msg {
  color: #ea3535;
}
.cancel-modal-container input {
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  position: relative;
  line-height: 17px;
  padding: 16px;
  border: 1px solid #edf1f7;
  border-radius: 49px;
}
.cancel-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
}
.cancel-buttons .back-button {
  border: 2px solid black;
  color: black;
}
.cancel-buttons .back-button:hover {
  color: #ffffff;
  background-color: black;
}
.cancel-buttons .cancel-button {
  border: 2px solid #ea3535;
  color: #ea3535;
}
.cancel-buttons .cancel-button:hover {
  color: #ffffff;
  background-color: #ea3535;
}
.cancel-buttons button {
  transition: 0.3s;
}
.cancel-buttons button:active {
  transform: translateY(4px);
}
.cancel-modal-container button {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  width: 100%;
  border-radius: 32px;
  padding: 16px;
  transition: 0.3s;
  background-color: transparent;
}
.cancel-modal-container .shipped-button {
  background-color: #4b85dc;
  color: white;
  border: 2px solid #4b85dc;
}
.cancel-modal-container .shipped-button:hover {
  background-color: white;
  color: #4b85dc;
}
.cancel-modal-container .cancel-button {
  display: block;
}
@media (max-width: $mobile-screen) {
  .cancel-modal-container {
    width: auto;
  }
}
