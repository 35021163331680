@import "src/styles/variables";

.inner-heading .btn {
  box-shadow: none;
}

.inner-heading .btn-nostyle {
  font-weight: 500;
}

.custom-file .custom-file-label {
  width: 116px;
  height: 116px;
  background: rgba(183, 207, 242, 0.15);
  border: 2px dashed #B7CFF2;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.custom-file .custom-file-label::after {
  display: none;
}

.custom-file .custom-file-label img {
  width: auto;
}

.add-product-field {
  padding-bottom: 24px;
  margin-bottom: 2px;
  border-bottom: 1px solid #DDDDDD;
}

.add-product-field label, .add-product-field h4 {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #808488;
  margin-bottom: 16px;
}

.add-product-field h4 {
  margin-bottom: 0 !important;
}

.delete-img-btn {
  background: white;
  border: 0;
  border-radius: 50%;
}

.mb-26 {
  margin-bottom: 26px;
}

.add-product-field .form-control {
  padding: 12px 16px;
  background-color: #EDF1F7;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 0;
  height: 42px;
}

.add-product-field p {
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  color: #BDC1C5;
  margin-top: 8px;
}

.add-product-field textarea.form-control {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  resize: none;
  height: 68px;
}

.input-group-append .btn.btn-dropdown {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  padding: 10px 16px !important;
  border-radius: 0 6px 6px 0 !important;
  box-shadow: none;
}

.input-group-append .btn.btn-dropdown:after {
  display: none;
}

.btn.btn-add-other {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #808488;
  background-color: transparent;
  border: 1px dashed #808488;
  border-radius: 8px !important;
  box-shadow: none;
  padding: 11px 24px !important;
}

.btn.btn-add-other:hover {
  color: #808488;
}

.btn-add-other img {
  margin-left: 9px;
}

.add-product-field.pick-color {
  padding-bottom: 31px;
}

.add-quantity .form-control {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.material-type-edit {
  background-color: transparent;
  border: 1px dashed #BDC1C5;
  border-radius: 4px;
  font-size: 16px !important;
  cursor: pointer;
  padding: 5px 10px;
  width: fit-content;
  margin: 10px 10px 0 0;
  color: #000000 !important;
}

.material-type-selected-edit {
  background-color: #EDF1F7;
  border: 1px dashed #BDC1C5;
  border-radius: 4px;
  font-size: 16px !important;
  cursor: pointer;
  padding: 5px 10px;
  width: fit-content;
  margin: 10px 10px 0 0;
  color: #000000 !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.simple-add-button {
  padding: 0;
  border: 0;
  width: fit-content;
  margin: 0;
  background-color: transparent;
  align-items: center;
  display: flex;
  color: #808488;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.border-bottom-0 {
  border-bottom: 0;
}

.delete-img {
  position: absolute;
  rotate: 45deg;
  top: -3px;
  background: white;
  right: -5px;
  border-radius: 50%;
}

.mainInfoContainer {
  display: flex;
  flex-direction: row;
}

.mainInfoItem {
th {
  overflow-wrap: break-word;
}
}

.mainInfoItemsWrapper {
  width: 100%;
  height: 100%;
}

.product-detail-thumbnail {
  position: relative;
  border-radius: 16px;
  max-width: 168px;
  max-height: 168px;
  height: 100%;
}

.product-detail-imgs img {
  width: 100%;
  height: 100%;
}

.product-detail-thumbnail-img {
  object-fit: cover;
}

.insta-icon {
  display: none;
}

.product-detail-thumbnail:hover .insta-icon {
  display: block;
  position: absolute;
  bottom: 1px;
  right: 0;
}

.product-detail-imgs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);;
  grid-template-rows: repeat(3, 1fr);
  justify-items: flex-start;
  row-gap: 15px;
  column-gap: 15px;
}

.mainInfoItems1.second {
  justify-self: flex-end;
}

.product-detail-imgs-Main {

  overflow: hidden;

  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;

  border-radius: 16px;
}

.custom-file {
  height: 100%;
}

.custom-file .custom-file-label img {
  width: auto;
  height: auto;
}

.custom-file .custom-file-label {
  width: 100%;
  height: 100%;
}

.addImg {
  height: 100%;
}

@media (max-width: $tablet-screen) {
  .mainInfoContainer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .product-detail-thumbnail {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 248px;
  }

  .mainInfoItems.imgs {
    width: 100%
  }

  .mainInfoItems {
    width: 100%;
  }

  .product-detail-imgs {
    display: grid;
    justify-items: flex-start;
    column-gap: 15px;

    grid-template-columns: repeat(3, 1fr);;
    grid-template-rows: repeat(2, 1fr);
  }


  .product-detail-one-img {
    display: grid;
    grid-template-columns: repeat(2, 1fr);;
    grid-template-rows: repeat(2, 1fr);
    justify-items: flex-start;
    row-gap: 15px;
    column-gap: 15px;
  }

  .product-detail-imgs-Main {
    overflow: hidden;

    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;

    border-radius: 16px;
  }

  .custom-file-label {
    margin-bottom: 15px;
  }


  .rowSimple {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

  }

}

@media (max-width: $tabletD-screen) {
  .product-detail-thumbnail {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 178px;
  }
}


@media (max-width: $tabletDMini-screen) {
  .product-detail-thumbnail {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 145px;
  }
}

@media (max-width: $iphoneMax) {
  .product-detail-thumbnail {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 91px;
  }
}
@media (max-width: $galaxyFold) {
  .product-detail-thumbnail {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 55px;
  }
}


