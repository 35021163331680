@import "src/styles/variables";

.story-modal {
  background-color: transparent;
  border: none;
  justify-content: center;
  align-items: center;
}
.instagram-container {
  border-radius: 50%;
  padding: 2px;
  background: #dddddd;
}
.read-more-button {
  border: none;
  background: transparent;
  padding: 0;
  color: #4b85dc;
  & svg {
    margin-left: 5px;
  }
}
.instagram-container.active {
  background: #833ab4;
  background: -webkit-linear-gradient(to bottom, #fcb045, #fd1d1d, #833ab4);
  background: linear-gradient(to bottom, #fcb045, #fd1d1d, #833ab4);
}
.buyer-profile-img {
  height: 80px;
  max-width: 80px;
  min-width: 80px;
  margin-bottom: 12px;
}

.buyer-profile-img img {
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}
.search-wrapper-buyer-info {
  width: fit-content;
  padding: 12px;
  margin: 12px auto 0px auto;
  background: #edf1f7;
  border-radius: 40px;
  cursor: pointer;
  &.show-input {
    width: 300px;
  }
}
.clear-input {
  cursor: pointer;
}
.search-container {
  display: grid;
  align-items: center;
  grid-template-columns: 24px auto 15px;
  grid-gap: 10px;
}

.input-wrap-buyer-info-mobile {
  display: none;
}

@media (max-width: $tabletDMini-screen) {
  .searchWrapper {
    margin-top: 10px;
  }
}

@keyframes searchAnimation {
  0% {
    opacity: 1;
    transform: rotateY(90deg);
  }
  50% {
    opacity: 0.5;
    transform: rotateY(0deg);
  }
}

.input-wrap-buyer-info {
  font-size: 14px;
  display: flex;
  flex-wrap: nowrap;
}
.search-button {
  transition: 0.3s;
  color: #ffffff;
  border: none;
  background-color: #4b85dc;
  &:hover {
    background-color: #0069d9;
  }
  &:active {
    transform: translateY(4px);
  }
}

.input-wrap-buyer-info input {
  width: 100%;
  background: #ffffff;
  border: none;
  border-radius: 8px 0 0 8px;

  margin: 0;
  padding: 0 12px;

  animation-name: searchAnimation;
  animation-duration: 500ms;
  animation-fill-mode: backwards;
}

@media (max-width: $mobile-screen) {
  .search-wrapper-buyer-info .search-container {
    display: block;
  }
  .search-wrapper-buyer-info .clear-input {
    display: none;
  }
  .input-wrap-buyer-info input,
  .input-wrap-buyer-info .search-button {
    display: none;
  }

  .input-wrap-buyer-info-mobile {
    display: contents;
    .clear-input {
      margin-left: 10px;
    }
  }

  .search-wrapper-buyer-info-mobile {
    width: fit-content;
    padding: 12px;
    margin: 12px auto 0px auto;
    background: #edf1f7;
    border-radius: 40px;

    &.show-input {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      width: 265px;
    }
  }

  .search-container-mobile {
    display: flex;
    align-items: center;
  }

  .search-wrapper-buyer-info {
    &.show-input {
      width: fit-content;
    }
  }

  .input-wrap-buyer-info-mobile input {
    width: 100%;
    background: #ffffff;
    border: none;
    border-radius: 8px 0 0 8px;

    margin-left: 10px;
    padding: 0 12px;

    animation-name: searchAnimation;
    animation-duration: 500ms;
    animation-fill-mode: backwards;
  }
}
