@import "src/styles/variables";

button.btn-connect-inst {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f09433;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}

.buyer-profile-topbar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.buyer-page-layout {
  padding-left: 248px;
  padding-right: 248px;
}

@media (max-width: $lg-screen) {
  .buyer-page-layout {
    padding: 0px;
  }
}

.buyer-profile-topbar-wrapper {
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #dddddd;
}

.buyer-profile-inner {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.buyer-img-wrapper {
  margin-right: 34px;
}

.buyer-profile-text {
  display: block;
  margin-right: 34px;
  max-width: 108px;
  width: 100%;
  text-align: center;
}

.show-subscriptions-button {
  cursor: pointer;
  & p {
    position: relative;
  }

  & p::after {
    content: "";
    position: absolute;
    width: 0px;
    height: 1px;
    display: block;
    background: black;
    transition: 300ms;
  }

  &:hover p::after {
    width: 100%;
  }
}

.buyer-profile-text:last-child {
  margin-right: 0;
}

.buyer-profile-text p {
  display: inline-block;
}
.buyer-profile-text h4 {
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
}

.buyer-profile-name {
  max-width: 40vw;
  width: 100%;
}

.buyer-profile-name h4 {
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
}

@media only screen and (max-width: 576px) {
  .buyer-profile-text h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
  }
}

.buyer-profile-text p {
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: #808488;
}

.settings-image {
  cursor: pointer;
  margin-top: 12px;
  padding: 12px;
  background-color: #edf1f7;
  border-radius: 32px;
}

@media (max-width: $md-screen) {
  .buyer-profile-topbar {
    justify-content: space-between;
  }

  .buyer-profile-topbar-wrapper {
    padding-bottom: 16px;
  }

  .userNameAlign {
    text-align: left;
  }
}

@media only screen and (max-width: 380px) {
  .buyer-profile-text h4 {
    font-weight: 600;
    font-size: 15px;
    line-height: 28px;
  }
}

.seller-product-card-item {
  align-items: center;
  margin-bottom: 12px;
  padding: 16px;

  background: #ffffff;

  border: 1px solid #edf1f7;
  border-radius: 24px;
}
.seller-products-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
}

.seller-products-title-wrapper {
  padding: 16px;
}

.seller-info-wrapper {
  margin-bottom: 8px;
  padding: 0 6px;
}
.seller-info-wrapper h4 {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}

.seller-images-wrapper {
  display: flex;
  margin-bottom: 16px;
  margin-left: 20px;
  margin-top: 15px;
  width: calc((100% - 40px) / 2);
}

.seller-product-image-item {
  margin-left: 4px;
  margin-top: 4px;
  width: calc((100% - 12px) / 3);
}

.seller-product-image {
  object-fit: cover;
  width: 100%;
  height: 200px;
}
.seller-product-image.number-image-1 {
  border-radius: 16px 0px 0px 16px;
}
.seller-product-image.number-image-3 {
  border-radius: 0px 16px 16px 0px;
}

.seller-info-image {
  width: 32px;
  height: 32px;

  border: 1px solid #edf1f7;
  border-radius: 24px;
}

.seller-product-text {
  margin-bottom: 16px;
}

.seller-product-text h4 {
  color: #000000;
  margin-bottom: 8px;
}

.seller-product-text span {
  font-size: 10px;
  line-height: 13px;
  color: #808488;
  display: block;
}

.product-card-options {
  margin-bottom: 16px;
  flex-direction: column;
  gap: 12px;
  padding: 16px 0;
  border-top: 1px dashed #edf1f7;
  border-bottom: 1px dashed #edf1f7;
}

.produt-card-option {
  display: flex;
  align-items: center;
  gap: 8px;
}

.produt-card-option p {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #808488;
}

.size-btn {
  box-sizing: border-box;
  padding: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  background: #ffffff;
  color: #808488;
  border: 1px solid #edf1f7;
  border-radius: 8px;
}

.size-btn.active {
  background: #edf1f7;
  border: 1px solid #4b85dc;
}

.produt-card-option-color {
  width: 24px;
  height: 24px;
  border-radius: 50px;
}

.color-selected {
  padding: 3px;
  border-radius: 50px;
  border: 1px solid #4b85dc;
}
.color-non-selected {
  padding: 3px;
  border-radius: 50px;
  border: 1px solid transparent;
}

.dropdown-menu-buyer {
  width: fit-content !important;
  border: 1px solid #edf1f7;
  box-shadow: 0px 24px 40px rgba(75, 133, 220, 0.16);
  border-radius: 16px;
  left: 0 !important;
  top: 0 !important;
  transform: translate(-100%, 0) !important;
}

.seller-product-wrapper {
  display: flex;
  margin-left: -20px;
  margin-top: -15px;
}

.seller-images-wrapper .seller-product-image-item {
  width: -webkit-fill-available;
  margin-left: 0px;
  margin-top: 0px;
}

.seller-product-description-wrapper {
  margin-left: 20px;
  margin-top: 15px;
  width: calc((100% - 40px) / 2);
}

.seller-product-image {
  border-radius: 16px;
  height: 300px;
}

@media (max-width: $mobile-screen) {
  .product-card-options {
    flex-direction: column;
    gap: 0px;
  }

  .buyer-profile-topbar {
    justify-content: center;
  }

  .seller-product-card-item {
    padding: 16px 8px;
  }

  .buyer-profile-text {
    margin-right: 0px;
  }

  .buyer-img-wrapper {
    margin-right: 0px;
  }

  .seller-product-description-wrapper {
    width: auto;
    margin: 0px;
  }

  .seller-product-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0px;
  }

  .seller-images-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    margin-left: 0px;
    margin-top: 0px;
    width: auto;
  }

  .produt-card-option {
    margin-bottom: 16px;
  }
  .produt-card-option:last-child {
    margin-bottom: 0px;
  }

  .seller-product-image-item {
    margin: 0;
    width: auto;
  }

  .seller-product-image {
    object-fit: cover;
    width: 100%;
    height: 200px;
    border-radius: 16px;
  }

  .seller-product-image.number-image-1 {
    border-radius: 16px;
  }
  .seller-product-image.number-image-3 {
    border-radius: 16px;
  }

  .carousel {
    width: 100%;
  }
  .carousel-control-next-icon {
    display: none;
  }
  .carousel-control-prev-icon {
    display: none;
  }

  .carousel-indicators {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 0px auto 8px auto;
    padding: 4px;
    gap: 4px;
    position: absolute;
    width: 28px;
    height: 12px;
    background: rgba(255, 255, 255, 0.24);
    border-radius: 14px;
  }

  .carousel-indicators [data-bs-target] {
    width: 4px;
    height: 4px;
    margin: 0px;
    border-radius: 50px;
    border: none;
    background: #ffffff;
  }
}
