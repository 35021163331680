body {
  background-color: #e5e5e5;
}
.main {
  text-align: center;
  max-width: 1440px;
  margin: 0 auto;
  padding-top: 32px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .logo {
    flex: 0 1 auto;
  }
  h1 {
    font-weight: 500;
    text-align: left;
    font-size: 40px;
    letter-spacing: -0.02em;
    color: #ffffff;
    margin-bottom: auto;
  }
}
.not-found-section {
  flex: 1 1 auto;
  max-height: 775px;
  margin: 32px 82px 50px;
  padding: 56px;
  background-image: url("../../Assets/images/404background.png");
  border-radius: 40px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
}
.bottom-nav_title {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  text-align: center;
  color: #edf1f7;
}
.bottom-nav_list {
  display: inline-grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 32px;
  li {
    text-decoration-line: underline;
    a {
      color: #edf1f7;
      letter-spacing: 0.01em;
      font-weight: 300;
      font-size: 16px;
      line-height: 20px;
    }
  }
}
@media screen and (max-width: 600px) {
  .main {
    h1{
    font-weight: 500;
    font-size: 32px;
    }
    .not-found-section{
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0;
      background-image: url("../../Assets/images/404mobile-background.png");
    }
  }
}
