:root {
  --bs-primary-rgb: 135, 206, 235;
  --bs-default-rgb: 70, 70, 70;
  --bs-gray-rgb: 180, 180, 180;
  --bs-text-opacity: 1;
}

body {
  background: #fff;
  font-family: "Lexend Deca", sans-serif;
}

.app-container {
  display: flex;
}
::placeholder {
  color: rgba(var(--bs-gray-rgb), var(--bs-text-opacity)) !important;
  opacity: 1;
}

:-ms-input-placeholder {
  color: rgba(var(--bs-gray-rgb), var(--bs-text-opacity));
}

::-ms-input-placeholder {
  color: rgba(var(--bs-gray-rgb), var(--bs-text-opacity));
}

a {
  text-decoration: none;
  color: rgba(var(--bs-default-rgb), var(--bs-text-opacity));
}

a:hover {
  color: rgba(var(--bs-default-rgb), var(--bs-text-opacity));
}

.btn {
  border-radius: 39px;
  border: 0;
  padding: 10px 58px;
}

.btn.outline {
  border: 1px solid #4B85DC;
  background: transparent;
  color: #4B85DC;
  box-shadow: none;
}

.btn.outline:hover {
  background: #4B85DC;
  color: white;
}

.insta-btn {
  background-color: transparent;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  border-top: 1px solid rgb(219,219,219);
  cursor: pointer;
  font-size: 14px;
  line-height: 1.5;
  margin: 0;
  min-height: 48px;
  padding: 4px 8px;
  text-align: center;
  -webkit-user-select: none;
  vertical-align: middle;
}

.btn-primary {
  background: rgba(var(--bs-primary-rgb), var(--bs-text-opacity));
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:disabled {
  background: rgba(var(--bs-default-rgb), var(--bs-text-opacity));
  box-shadow: none;
}

.form-check-input:focus,
.form-check-input:active {
  border-color: rgba(var(--bs-gray-rgb), var(--bs-text-opacity));
  box-shadow: none;
}

.text-right {
  text-align: right;
}

.row-fluid {
  width: 100%;
  max-width: 100%;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.row-no-gutter-x {
  --bs-gutter-x: 0;
}

.primary-scroll::-webkit-scrollbar {
  width: 9px;
}

/* Track */
.primary-scroll::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 10px;
}

/* Handle */
.primary-scroll::-webkit-scrollbar-thumb {
  background: rgba(var(--bs-primary-rgb), var(--bs-text-opacity));
  border-radius: 10px;
}

/* Handle on hover */
.primary-scroll::-webkit-scrollbar-thumb:hover {
  background: rgba(var(--bs-primary-rgb), var(--bs-text-opacity));
}

.justify-center {
  justify-content: center;
}

.form-check-input:checked {
  background-color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity));
  border-color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity));
}

.form-switch .form-check-input:focus {
}

.text-left {
  text-align: left;
}

.application-status {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  display: inline-block;
  padding: 5px 8px;
  border-radius: 2px;
  margin-top: 5px;
}

.application-status.inprogress {
  background-color: #fcf0d3;
  color: #efb321;
}

.application-status.approved {
  background-color: #cae4d0;
  color: #2fa949;
}

.btn-center {
  display: block;
  margin: 0 auto;
}

.eye-slash {
  height: 15px;
  float: right;
  margin-top: -25px;
  margin-right: 10px;
  cursor: pointer;
  z-index: 10;
  position: inherit;
}

.height-20 {
  height: 20px;
}

@media screen and (max-width: 767px) {
  .sm-btn-center {
    margin: 0 auto;
    display: block;
  }

  .sm-text-center {
    text-align: center !important;
  }
}
