tbody {
  display: block;
  max-height: 200px;
  overflow: auto;
  text-align: center;
}
th {
  overflow-wrap: break-word;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  text-transform: capitalize;
  max-width: 20px;
}
.table {
  margin-bottom: 0rem;
}
.table > :not(caption) > * > * {
  padding: 10px 3px !important;
  word-wrap: break-word;
  border-top: 0px;
}

@media (max-width: 1271px) {
  th {
    font-size: 12px;
  }
}

thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
tbody tr.property-info td {
  white-space: pre-wrap;
}

td {
  white-space: nowrap;
  width: 1px;
}

td input {
  width: 100%;
  height: 50%;
}

tbody::-webkit-scrollbar {
  width: 5px;
  max-height: 10px;
}

tbody::-webkit-scrollbar-thumb {
  background: #4b85dc;
  border-radius: 10px;
}

tbody::-webkit-scrollbar-thumb:hover {
  background: gray;
}
