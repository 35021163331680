@import "src/styles/variables";

.seller-switcher-wrapper {

  .react-switch-bg {
    height: 49px !important;
    width: 142px !important;
    border-radius: 32px !important;
    align-items: center;
    justify-content: space-around;
    display: flex;
  }

  .react-switch-bg > div {
    opacity: 1 !important;
    z-index: 1;
    position: relative !important;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .react-switch-handle {
    width: 69px !important;
    height: 41px !important;
    border-radius: 32px !important;
    top: 4px !important;
  }

  p {
    font-weight: 500;
  }

  .seller-switcher-on {

    .react-switch-handle {

      transform: translateX(70px) !important;
    }

  }

  .seller-switcher-off {

    .react-switch-handle {

      transform: translateX(3px) !important;
    }

  }

  .switcher-active-p {
    color: #FFFFFF;

  }

  .switcher-passive-p {
    color: $grey;
  }


  @media (max-width: $md-screen) {
    .react-switch-bg {
      width: 208px !important;
    }

    .react-switch-handle {
      width: 100px !important;
      height: 41px !important;
      border-radius: 32px !important;
      top: 4px !important;
    }

    .seller-switcher-on {

      .react-switch-handle {

        transform: translateX(105px) !important;
      }

    }
  }


}