@import "src/styles/variables";

.footer-buttons {
  margin-top: 32px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer-buttons .in-processing-button {
  background-color: #e504c3;
  border: 2px solid #e504c3;
  transition: .3s;
  color: white;
}
.footer-buttons .in-processing-button:hover {
  background-color: white;
  color: #e504c3;
}
.footer-buttons .to-payout-button {
  border: 2px solid #008040;
  background-color: #008040;
  color: white;
  transition: .3s;
}
.footer-buttons .to-payout-button:hover{
  background-color: white;
  color: #008040;
}
.footer-buttons .pending-payout {
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.03em;
  color: #808488;
}
.footer-buttons .completed-payout {
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.03em;
  color: #008040;
}

.footer-buttons .cancelled-payout {
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.03em;
  color: #ea3535;
}

.footer-buttons .shipped-button {
  border: 2px solid #4b85dc;
  color: #4b85dc;
}
.footer-buttons .shipped-button img {
  margin-left: 14px;
}
@media (max-width: $mobile-screen) {
  .footer-buttons .to-payout-button,
  .footer-buttons .shipped-button,
  .footer-buttons .in-processing-button {
    width: 100%;
  }
  .footer-buttons {
    margin: 0;
  }
}
