@import "src/styles/variables";

.delivery-info label {
  font-weight: 600;
  line-height: 18px;
  margin-bottom: 8px;
}

.react-tel-input input {
  width: 100% !important;
  max-width: 300px !important;
}

.delivery-info-bottom-btn {
  @media (max-width: $mobile-screen) {
    width: 100%;
  }
}

.delivery-info-item {
  margin-bottom: 16px;
  padding: 24px;
  background-color: #ffffff;
  border-radius: 16px;
}

.delivery-info-item .col-12 {
  background-color: #ffffff;
  border-radius: 16px;
}

#f .f-center-wrap {
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  background-color: transparent !important;
}

.f-embed {
  background-color: transparent !important;
}
.f-container {
  background-color: transparent !important;
}

#f .f-header {
  background-color: transparent !important;
}

#f .f-scrollbar-wrap {
  height: 100%;
  min-height: inherit;
  overflow-x: hidden;
  overflow-y: auto !important;
}

.dotted-lines-top {
  border-top: 2px dashed #4b85dc85;
  padding-top: 10px;
}
.dotted-lines-bottom {
  border-bottom: 2px dashed #4b85dc85;
  padding-bottom: 10px;
}

.f-svg-security {
  width: 30px;
}

.dropdown-button-width-100 {
  width: 100%;
}
