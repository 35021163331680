@import "src/styles/variables";

.order-history_back-button {
  background: transparent;
  display: flex;
  border: none;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.01em;
  color: #282323;
  align-items: center;
}
.order-history-container {
  padding: 24px 16px;
}
.order-history-header {
  margin-bottom: 24px;
}
.order-history-body {
  padding-top: 16px;
  border-top: 1px solid #dddddd;
}
.order-history-body h4 {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #282323;
}
.order-history-wrap {
  display: grid;
  margin-top: 16px;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
}
.order-history-date {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.03em;
  color: #000000;
  padding-bottom: 16px;
  border-bottom: 1px solid #dddddd;
}
.order-history-status {
  margin-top: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.03em;
  color: #000000;
}
.order-history-status span {
  margin-left: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.03em;
}
@media (max-width: $mobile-screen) {
  .order-history-wrap{
    grid-template-columns: 1fr;
  }
}