@import "src/styles/variables";

.order-info {
  padding: 16px;
}
.order-info-drawer {
  height: auto !important;
  min-height: 534px;
  max-height: 88vh;
  border-radius: 32px;
  bottom: 16px !important;
  margin: 0 auto;
  overflow-y: auto;
  width: calc(100% - 32px) !important;
}
@media (max-width: $mobile-screen) {
  .order-into-body {
    padding: 10px 0;
  }
}
