@import "src/styles/variables";

.angle-icon {
  width: 0;
  height: 0;
}
.angle-icon.down {
  border-style: solid;
  border-width: 4px 4px 0 4px;
  border-color: #808488 transparent transparent transparent;
}
.angle-icon.up {
  border-style: solid;
  border-width: 0 4px 4px 4px;
  border-color: transparent transparent #808488 transparent;
}

.dd-wrapper {
  position: relative;
  width: 260px;
  font-size: 14px;
  user-select: none;
}
.dd-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border: 1px solid #edf1f7;
  border-radius: 49px;
  background-color: white;
  width: 100%;
  cursor: default;
  cursor: pointer;
  padding: 15px 22px 15px 24px;
}

.dd-header-title {
  font-weight: 500;
  color: #808488;
}

.dd-list {
  position: absolute;
  z-index: 10;
  width: 100%;
  max-height: 215px;
  border: 1px solid rgb(223, 223, 223);
  background-color: white;
  text-align: left;
  overflow: auto;
}
.dd-list-item {
  display: inline-block;
  overflow: hidden;
  width: 100%;
  padding: 8px 10px;
  font-size: 14px;
  line-height: 1.6rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: default;
  transition: 0.3s;
  cursor: pointer;
}
.dd-list-item.selected,
.dd-list-item:hover {
  background-color: #e3e3e3;
}
.dd-list-item.no-result {
  font-weight: normal;
  cursor: default;
}
.dd-list-item.no-result:hover {
  background-color: transparent;
  color: black;
}
@media (max-width: $mobile-screen) {
  .dd-wrapper {
    width: 100%;
  }
}
