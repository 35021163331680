@import "src/styles/variables";

#truncateLongTexts {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#truncateLongTexts2 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-mobile-phone-field {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-bottom: 22px;
}
.buyer-name-field {
  display: flex;
  flex-direction: column;
}
.user-mobile-phone-input {
  padding: 10px 16px;
  background-color: #ffffff;
  border: none;
  border-radius: 6px;
  color: #282323;
}
.user-mobile-phone-input-invalid {
  border: 1px solid #ff0000;
}

.buyer-name-input {
  padding: 10px 16px;
  background-color: #ffffff;
  border: none;
  border-radius: 6px;
  color: #282323;
}

.dropdown-menu.show {
  min-width: auto;
  width: 100%;
}

.dropdown-item {
  text-align: center;
  cursor: pointer;
}

.local-delivery-dropdown-menu input {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  background: #ffffff;
  border-radius: 6px;
  padding: 8px 16px;
  border: 0;
  margin-bottom: 16px;
  height: 41px;
}

@media (max-width: $mobile-screen) {
  .local-delivery-dropdown-button {
    white-space: pre-wrap;
  }
}
