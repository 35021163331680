.product-card {
  max-width: 160px;
  height: fit-content;
  background: #ffffff;
  border: 1px solid #edf1f7;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 12px;
  position: relative;
  .product-card-link {
    &.disabled {
      user-select: none;
      pointer-events: none;
      cursor: not-allowed;
    }
    &:hover {
      text-decoration: none;
    }
    &:hover h4 {
      text-decoration: underline;
    }
  }
}

.product-card-img img {
  width: 160px;
  height: 160px;
  object-fit: cover;
}

.product-card-text {
  padding: 12px;
  min-height: 90px;
  display: flex;
  flex-direction: column;
}

.product-card-text h4 {
  color: #000000;
  font-weight: 400;
  line-height: 15px;
  margin-bottom: 4px;
  font-size: 12px;
}

.product-card-text p {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: black;
  text-align: right;
  margin-top: auto;
}
.product-card-text.product-sold {
  h4,
  p {
    text-decoration: line-through !important;
  }
}
.product-sold-bloc {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  top: 0;
  cursor: pointer;
  background-color: rgba(236, 244, 255, 0.4);
  span {
    font-weight: 500;
    border-radius: 8px;
    font-size: 12px;
    line-height: 15px;
    background: white;
    padding: 8px;
    color: #4b85dc;
    margin-top: 65px;
  }
}

@media only screen and (max-width: 576px) {
  .product-card-text {
    padding: 4px;
  }

  .product-card {
    max-width: 104px;
    background: #ffffff;
    border: 1px solid #edf1f7;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 12px;
  }

  .product-card-img img {
    width: 104px;
    height: 104px;
    object-fit: cover;
  }
  .product-card-text {
    min-height: 65px;
    h4,
    p {
      font-size: 12px;
    }
  }
  .product-sold-bloc span {
    margin-top: 38px;
  }
}
