@import "src/styles/variables";

.subscription-group-card {
    max-width: 84px;
    margin-right: 32px;
    position: relative;
    background-color: transparent;
    width: 100%;
}

.subscription-group-card::after {
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    border-radius: 50%;
    border: 1px solid #DDDDDD;
    height: 64px;
    width: 64px;
    z-index: 1;
}

 .subscription-group-card.active-group::after  {
    border: 1px solid #4B85DC;
    background: #4B85DC;
}

.subscription-group-card.not-active-group {
    cursor: pointer;
}

.group-card-wrapper {
    position: relative;
    padding-top: 32px;
}

.edit-icon {
    top: -16px;
    left: -12px;
    position: absolute;

    :hover {
        path {
            fill: $blue;
        }
    }

    circle, path {
        cursor: pointer;
    }
}

.edit-icon-not-display {
    display: none;
}

.edit-icon-mobile {
    animation: ani 3.5s forwards;
    @keyframes ani {
        0% {
            opacity: 0;
        }
        15% {
            opacity: 1;
        }
        85% {
            opacity: 1;
        }
        100% {
            opacity: 0;
            display: none;
        }
    }
}

.sub-group-card-profiles {
    width: 84px;
    height: 84px;
    justify-content: center;
    align-items: center;
}


.sub-group-card-img {
    max-width: 40px;
    width: 100%;
    height: 40px;
    background-color: #4B85DC;
    border: 1px solid #E9EDF3;
    border-radius: 50%;
    gap: 4px;
    overflow: hidden;
    display: flex;
    align-items: center;
    z-index: 2;
}

.sub-group-card-img img {
    width: 40px;
}

.sub-group-card-img.active-group {
    border: 1px solid #4B85DC;
}

.sub-group-card-img:nth-child(2n) {
    margin-right: 0;
}

.sub-group-card-img {
    width: 100%;
}

.group-checkbox {
    position: absolute;
    height: 50px;
    width: 50px;
    z-index: 10;
}

.sub-group-card-text {
    margin-top: 8px;
}

.sub-group-card-text p {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #808488;
}