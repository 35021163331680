@import "src/styles/variables";

.navbar-main {
  margin: 24px 0;

  @media (max-width: $mobile-screen) {
    margin: 32px 0 12px;
  }
}
.basis-25 {
  flex-basis: 25%;
}
.language-selector {
  display: flex;
  margin-right: 15px;
  gap: 8px;
  background: white;
  padding: 12px 16px;
  border-radius: 24px;

  @media (max-width: $mobile-screen) {
    border-radius: 32px;
  }
}
.header-right-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-basis: 25%;
}

.language-selector svg.active {
  border-color: #4b85dc;
}

.language-selector svg {
  border: 2px solid transparent;
  border-radius: 50%;
  width: 38px;
  height: 38px;

  @media (max-width: $mobile-screen) {
    width: 24px;
    height: 24px;
  }
}

.nav-link__active svg path {
  fill: $active-link-color;
}

.main-nav-tabs {
  border-radius: 32px;
  background-color: #ffffff;
  /* max-width: 232px; release 2 */
  max-width: 226px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  height: 56px;

  @media (max-width: $mobile-screen) {
    max-width: 100%;
    justify-content: space-evenly;
    margin-top: 16px;
    height: 48px;
  }
}

.main-nav-tabs .nav-item {
  position: relative;
  display: flex;
  align-items: center;
}
.main-nav-tabs .nav-item .cart-count {
  position: relative;
}
.main-nav-tabs .nav-item .cart-count .badge {
  max-height: 21px;
  max-width: 21px;
  position: absolute;
  bottom: -10px;
  right: -10px;
  border-radius: 50%;
  background: red;
  color: white;
  aspect-ratio: 1 / 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-nav-tabs .nav-item:last-child::after {
  width: 0;
}

.main-nav-tabs .nav-link {
  padding: 0;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: inline-block;
  padding: 17px 26px 18px;

  @media (max-width: $mobile-screen) {
    padding: 10px 30px;
  }
}

.main-nav-tabs .nav-item.show .nav-link,
.main-nav-tabs .nav-link.active {
  background-color: transparent;
}

.login-wrapper {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 56px;
  background-color: #4b85dc;
  border-radius: 28px;
  transition: all 0.5s ease;
  cursor: pointer;
  &.active {
    width: 120px;
    transition: all 0.5s ease;
    background-color: #4b85dc;
  }
  &.logout {
    width: 76px;
  }
  .title {
    font-weight: 700;
    color: #fff;
    text-align: center;
  }
}

@media (max-width: $mobile-screen) {
  .header-right-buttons {
    flex-wrap: wrap;
    margin: 10px auto 0;
    flex-basis: auto;
    justify-content: center;
  }
  .google-button-wrap {
    margin: 10px 0;
  }
}
