@import "src/styles/variables";

.searchWrapper {
    height: 48px;
    padding-right: 15px;
    padding-left: 15px;
    background: #EDF1F7;
    border-radius: 32px;

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 71px;

    &.showInput {
        width: 300px;
    }
}

@keyframes searchAnimation{
    0%{
        opacity: 1;
        transform: rotateY(90deg);
    }
    50%{
        opacity: 0.5;
        transform: rotateY(0deg);
    }
}

.inputWrap {
    max-width: 70%;

    font-size: 14px;
}

.inputWrap input {
    width: 100%;
    height: 32px;
    background: #FFFFFF;
    border: none;
    border-radius: 8px;

    margin-left: 10px;
    margin-right: 20px;
    padding: 0 12px;

    animation-name: searchAnimation;
    animation-duration: 500ms;
    animation-fill-mode: backwards;
}

.searchContainer {
    display: flex;
    align-items: center;
}

.searchContainer, .filterContainer {
    cursor: pointer;
}

@media (max-width: $tabletDMini-screen) {
    .searchWrapper {
        margin-top: 10px;
    }
}