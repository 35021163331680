  @import "src/styles/variables";

  .action-cell {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .action-icon {
    width: 24px;
    min-width: 24px;
    height: 24px;
    background: #edf1f7;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .action-cell .to-payout-button {
    background-color: #008040;
    border: 2px solid #008040;
    margin-right: 10px;
    color: white;
    align-items: center;
    white-space: nowrap;
    font-weight: 700;
    line-height: 17px;
    transition: 0.3s;
    padding: 4px 19px;
    border-radius: 18px;
  }
  .action-cell .to-payout-button:hover {
    background-color: white;
    color: #008040;
  }
  .action-cell .to-payout-button:active {
    transform: translateY(4px);
  }
  @media (max-width: $mobile-screen) {
    .action-cell {
      order: 1;
      flex-basis: 24px;
    }
  }
