.list-unstyled {
  max-height: 20em;
  overflow: auto;
}

.list-unstyled::-webkit-scrollbar {
  width: 9px;
}

/* Track */
.list-unstyled::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 10px;
}

/* Handle */
.list-unstyled::-webkit-scrollbar-thumb {
  background: rgba(var(--bs-primary-rgb), var(--bs-text-opacity));
  border-radius: 10px;
}

/* Handle on hover */
.list-unstyled::-webkit-scrollbar-thumb:hover {
  background: rgba(var(--bs-primary-rgb), var(--bs-text-opacity));
}