@import "src/styles/variables";

.order-details {
  color: $grey;
  display: flex;
  justify-content: center;

  .order-details-delivery {
    width: 360px;
    word-break: break-word;
    @media (max-width: $mobile-screen) {
      width: 100%;
    }
  }

  .order-details-delivery-title {
    font-weight: 300;
    font-size: 14px;
  }

  .order-details-delivery-date-title {
    font-weight: 600;
  }

  .order-details-delivery-date-info {
    font-weight: 300;
    font-size: 14px;
  }

  .order-details-delivery-address-title {
    font-weight: 600;
  }

  .order-details-delivery-address-info {
    font-weight: 300;
    font-size: 14px;
  }

  .order-details-product {
    display: flex;
    flex-direction: column;
  }

  .order-details-product-info-left {
    display: flex;
    justify-content: space-between;
  }

  .order-details-product-info-img img {
    width: 56px;
    height: 56px;
    object-fit: cover;
    border-radius: 8px;

    @media (max-width: $tablet-screen) {
      width: 40px;
      height: 40px;
    }
  }

  .order-details-product-info-name-wrapper {
    width: 152px;
  }

  .order-details-product-info-name {
    font-weight: 600;
    font-size: 14px;
    color: $black;
  }

  .order-details-product-info-meta {
    font-weight: 300;
    font-size: 12px;
  }

  .order-details-product-info-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 152px;

    @media (max-width: $tablet-screen) {
      align-items: flex-start;
      width: auto;
    }
  }

  .order-details-product-info-grey-title {
    font-weight: 300;
    font-size: 12px;
  }

  .order-details-product-info-black-value {
    font-weight: 600;
    font-size: 14px;
    color: $black;
  }

  .order-details-payment-row-title {
    font-weight: 600;
    width: 344px;
  }

  .order-details-payment-row-value {
    font-weight: 300;
    font-size: 14px;
  }

  .order-details-payment-row-value__inAll {
    font-weight: 700;
    font-size: 14px;
  }
}
