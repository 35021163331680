@import "src/styles/variables";

.orders-table {
  background: #ffffff;
  box-shadow: 0px 24px 40px rgba(75, 133, 220, 0.16);
  border-radius: 32px;
  overflow: hidden;
  margin-top: 24px;
}
.orders-table * {
  font-size: 12px;
}
.orders-table .no-data {
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  color: #808488;
  letter-spacing: 0.03em;
  text-align: center;
  margin: 20px 0;
}
.orders-table-header {
  border-bottom: 1px solid #dddddd;
  padding: 14px 16px 16px 16px;
  display: flex;
  justify-content: space-between;
}
.orders-table-body {
  padding: 16px;
  overflow-x: auto;
}
.orders-table-footer {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 29px;
}
.orders-table-row {
  display: grid;
  width: fit-content;
  grid-template-columns:
    minmax(200px, 28fr) minmax(160px, 15fr) minmax(160px, 15fr) minmax(160px, 13fr)
    minmax(160px, 13fr) 200px;
  column-gap: 12px;
  border: 1px solid #ecf4ff;
  padding: 16px;
  border-radius: 16px;
  cursor: pointer;
  transition: 0.3s;
}
.orders-table-row.loading {
  opacity: 0.3;
  pointer-events: none;
}
.orders-table-row:hover {
  background-color: #f1f1f1;
}
.default-cell p:first-child {
  font-weight: 300;
  text-align: left;
  line-height: 15px;
  color: #000000;
}
.default-cell p:last-child {
  font-weight: 600;
  text-align: left;
  line-height: 15px;
  letter-spacing: 0.03em;
  color: #000000;
}
@media (max-width: $mobile-screen) {
  .orders-table-header {
    flex-direction: column;
    align-items: center;
  }
  .orders-table-row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
  }
  .default-cell {
    order: 2;
    width: 100%;
    margin-bottom: 16px;
  }
  .default-cell:first-child {
    order: 1;
    width: auto;
    flex-grow: 1;
  }
}

.orders-table-header .to-payout-button {
  background-color: #008040;
  border: 2px solid #008040;
  margin-right: 10px;
  color: white;
  align-items: center;
  white-space: nowrap;
  font-weight: 700;
  line-height: 17px;
  transition: 0.3s;
  padding: 4px 19px;
  border-radius: 18px;
}
.orders-table-header .to-payout-button:hover {
  background-color: white;
  color: #008040;
}
.orders-table-header .to-payout-button:active {
  transform: translateY(4px);
}