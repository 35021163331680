@import "src/styles/variables";

.map-inner{
  @media (max-width: $mobile-screen) {
    width: 100%;
    height: 480px;
  }
}

.map-wrapper{
  @media (max-width: $mobile-screen) {
    padding-bottom: 24px;
  }
}