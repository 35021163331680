@import "src/styles/variables";

.search-wrapper {
  padding-right: 15px;
  border-radius: 32px;

  width: 320px;
  height: 49px;
}

.search-input {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  width: 100%;
  height: 32px;
  background: transparent;
  border: none;
  border-radius: 8px;
}

.search-input-wrap {
  padding: 8px 16px 8px 24px;
  display: flex;
  align-items: center;
  border: 1px solid #edf1f7;
  border-radius: 49px;
}
@media (max-width: $mobile-screen) {
  .search-wrapper {
    margin-bottom: 16px;
    padding: 0;
    width: 100%;
  }
  .search-input-wrap{
    width: 100%;
  }
}
