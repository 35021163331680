@import "src/styles/variables";
.accordion-content-enter {
  overflow: hidden;
  max-height: 0;
}

.accordion-content-enter-active {
  max-height: 1000px;
  transition: max-height 0.6s ease-in-out;
}

.accordion-content-exit {
  max-height: 1000px;
}

.accordion-content-exit-active {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.4s cubic-bezier(0, 1, 0, 1);
}

.cart-main-section .subscription-group-heading {
  padding-top: 0;
  border-bottom: 1px solid #dddddd;
  margin-bottom: 24px;
}

.cart-main-section .subscription-group-heading .profile-setting {
  position: relative;
}

.cart-main-section .subscription-group-heading .profile-setting a {
  padding-left: 0;
}

.shopping-cart-section{
  margin-bottom: 24px;
  margin-right: 40px;

  @media (max-width: $md-screen) {
    margin-right: 0;
  }

  h3 {
    margin-bottom: 16px;
  }
}

.cart-items-scroll {
  height: calc(100vh - 206px);
  //overflow-y: overlay;
}

.cart-items-scroll::-webkit-scrollbar {
  width: 5px;
  position: absolute;
  display: none;
}

.cart-items-scroll::-webkit-scrollbar-track {
  box-shadow: none;
  background-color: transparent;
}

.cart-items-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(221, 221, 221, 0.5);
  outline: none;
  border-radius: 5px;
}

.cart-items-scroll:hover::-webkit-scrollbar {
  display: block;
}

.cart-item {
  padding-bottom: 16px;
  margin-top: 16px;
  border-bottom: 1px solid #dddddd;
}

.cart-item-img-container {
  border-radius: 8px;
  position: relative;
}

.cart-item-img-container .cart-item-img  {
  //width: 100%;
  //height: 100%;
  width: 68px;
  height: 68px;
  object-fit: cover;
  border-radius: 8px;
}

.cart-item-text {
  margin-left: 15px;
}

.cart-item-text h4 {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.cart-item-text h4 span {
  font-weight: 700;
}

.cart-item-text p {
  color: #808488;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.cart-item-price {
  margin-left: auto;
}

.cart-item-price p {
  color: #808488;
  line-height: 18px;
  font-weight: 600;
  text-align: right;
}

.discount-price {
  text-decoration: line-through;
}

.cart-item-price span {
  color: #e44646;
  line-height: 18px;
  font-weight: 600;
  display: block;
  margin-top: 4px;
  text-align: right;
}

.delete_img {
  position: absolute;
  right: 18px;
  top: -10px;
}

.delete-btn {
  width: 30px;
  height: 30px;
  //background-color: #e44646;
  background-color: #FFFFFF;
  position: absolute;
  border: 0;
  border-radius: 50%;
  padding: 6px 7px;
  margin-top: -5px;
  margin-left: -5px;
  display: none;
}

.delete-btn img {
  width: 100%;
  vertical-align: top;
}

.cart-item .delete-btn {
  display: block;
}

.delivery-section {
  padding: 32px;
  background: #ecf4ff;
  border-radius: 32px;

  @media (max-width: $mobile-screen) {
    padding: 32px 16px;
  }
}

.delivery-heading {
  margin-bottom: 32px;
}

.delivery-heading h2 {
  width: 50%;
  line-height: 25px;
}

.heading-progress-bar {
  height: 4px;
  background: #ffffff;
  border-radius: 2px;
  position: relative;
  margin-top: 12px;
}

.heading-progress-bar::after {
  content: "";
  height: 4px;
  width: 50%;
  background-color: #4b85dc;
  border-radius: 2px;
  position: absolute;
  left: 0;
  top: 0;
}

.delivery-info-scroll {
  /* height: calc(100vh - 363px);
    overflow-y: overlay;
    overflow-x: hidden; */
  /* padding-bottom: 50px; */
  position: relative;
}

.delivery-info-scroll::-webkit-scrollbar {
  width: 5px;
  position: absolute;
  display: none;
  z-index: 999;
}

.delivery-info-scroll::-webkit-scrollbar-track {
  box-shadow: none;
  background-color: transparent;
}

.delivery-info-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(221, 221, 221, 0.5);
  outline: none;
  border-radius: 5px;
}

.delivery-info-scroll:hover::-webkit-scrollbar {
  display: block;
}

.payment-info-list:last-child {
  margin-bottom: 16px;
}

.mt-6 {
  margin-top: 6px;
}

.flex-align-center {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.update-cart-btn {
  border: 0;
  border-radius: 8px;
  background: #4b85dc;
  color: white;
  font-size: 14px;
  padding: 3px 10px;
  margin-top: 8px;
}

.success-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.card-success-icon {
  text-align: center;
  margin-top: 40px;
  margin-bottom: -10px;
}

.payment-success-title {
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 26px;
}

.payment-success-subtitle {
  font-size: 18px;
  font-weight: 500;
  color: #808488;
  width: 100%;
  text-align: center;
}

.payment-success-link {
  font-size: 18px;
  font-weight: 500;
  color: #808488;
  margin-bottom: 20px;
}

.amount-currency-container {
  display: flex;
  justify-content: center;
  margin-bottom: 26px;
}
.amount-success {
  font-size: 34px;
  font-weight: 500;
}
.amount-small-success {
  font-size: 22px;
  font-weight: 500;
  margin-top: 4px;
}
.currency-success {
  margin-left: 12px;
  font-size: 34px;
  font-weight: 500;
}

.payment-id-container {
  width: 100%;
  border: 1px solid #808488;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.payment-id-text {
  font-size: 18px;
  font-weight: 500;
  color: #808488
}
.payment-id-number {
  font-size: 18px;
  font-weight: 500;
  color: #808488;
}

.security-auth-container {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}
.security-auth-icon {
  margin-right: 5px;
}
.security-auth-text {
  font-size: 16px;
  font-weight: 500;
  margin-left: 10px;
}