$galaxyFold: 282px;
$iphoneMax: 450px;
$mobile-screen: 576px;
$tabletDMini-screen: 730px;
$iPadMini-screen: 769px;
$tabletD-screen: 850px;
$tablet-screen: 1050px;
$md-screen: 767.5px;
$lg-screen: 1200px;
$gray-border: 1px solid #DDDDDD;
$light-gray-border: 1px solid #EDF1F7;
$active-link-color: #4B85DC;
$grey: #808488;
$blue: #4B85DC;
$black: #282323