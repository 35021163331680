
.product-detail-imgs .row {
    margin-right: -6px;
    margin-left: -6px;
}

.product-detail-imgs .col-4 {
    padding-left: 6px;
    padding-right: 6px;
}

.product-detail-thumbnail-big {
    box-shadow: 0 2px 1px rgba(75, 133, 220, 0.15);
    border-radius: 16px;
    margin-bottom: 12px;
    overflow: hidden;
    position: relative;
}

.insta-icon {
    position: absolute;
    bottom: 24px;
    right: 24px;
    height: 32px;
    width: 32px;
}

.product-detail-thumbnail {
    border-radius: 12px;
    overflow: hidden;
}

.product-detail-imgs img {
    width: 100%;
    //max-height: 374px;
}

.product-detail-text h2 {
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #000000;
    margin-bottom: 24px;
}

.product-detail-text p {
    font-size: 16px;
    line-height: 20px;
    color: #808488;
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid #DDDDDD;
}

.product-detail-text p.price-tag {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #4B85DC;
    margin: 0;
    padding: 0;
    border: 0;
}

.product-detail-card {
    padding: 32px;
    background: #ECF4FF;
    border-radius: 24px;
}

.selection-div {
    padding-bottom: 24px;
    margin-bottom: 26px;
    border-bottom: 1px solid rgba(75, 133, 220, 0.2);
}

.product-detail-card .selection-div:last-child {
    padding-bottom: 0;
    border: 0;
}

.selection-div h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    margin-bottom: 16px;
}

.pick-color ul li {
    margin-right: 8px;
}

.pick-color .btn-nostyle {
    width: 24px !important;
    height: 24px;
    border: 1px solid #808488;
    border-radius: 50%;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pick-color .btn-nostyle.selected{
    box-shadow: 0 0 6px #484848;
}

.quantity-add  .btn-nostyle{
    width: 20px !important;
}

.pick-quantity h4 {
    margin-bottom: 0;
}

.pick-quantity button {
    margin-right: 10px;
    height: 20px;
    width: 20px;
}

.pick-quantity button:first-child {
    border: 2px solid #4B85DC;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pick-quantity button:last-child {
    margin-right: 0;
    margin-left: 10px;
}

.pick-quantity button:last-child img {
    vertical-align: top;
}

.pick-quantity .form-control {
    border: 0;
    background-color: transparent;
    margin-bottom: 0;
    max-width: 18px;
    text-align: center;
    padding: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    height: 22px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.btn.btn-border {
    background-color: transparent;
    padding: 12px 16px !important;
    border: 1px solid #4B85DC;
    border-radius: 31px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #4B85DC;
    box-shadow: none;
}

.btn-border:hover, .btn-border:active, .btn-border.active {
    background-color: #4B85DC;
    color: #ffffff;
}

.btn-border img {
    margin-right: 6px;
}

.pick-size .btn-border, .pick-material .btn-border {
    margin: 3px 6px 3px 0;
}

.pad-0 {
    padding: 0;
}

.sm-thumbnail {
    height: 116.75px;
    width: 116.75px;
    min-width: 116.75px;
    min-height: 116.75px;
    max-height: 116.75px;
}

.flex-wrap {
    flex-wrap: wrap;
}

.item-detail-mini-image {
    width: 116px;
    height: 116px;
    object-fit: cover;
    border-radius: 12px;
}
