.modal-options .modal-body h2 {
    margin-bottom: 16px;
}

.modal-options .btn-border {
    border-radius: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 15px;
}