@import "src/styles/variables";

.dot-status {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 8px;
}
.status-cell {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.status-date-cell {
  margin-top: 7px;
}
.status-block {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: $mobile-screen) {
  .status-cell {
    order: 2;
    padding-top: 16px;
    border-top: 1px solid #dddddd;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
