@import "src/styles/variables";

.profile-view-main .subscription-group-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.add-product-modal {
  border-radius: 40px;
}
.add-product-modal .modal-body {
  padding: 48px 80px;
  @media (max-width: $mobile-screen) {
    padding: 25px 40px;
  }
}
.member-profile-topbar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.member-profile-topbar-wrapper {
  padding-bottom: 40px;
  border-bottom: 1px solid #dddddd;
}

.member-profile-inner {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.member-profile-img {
  height: 80px;
  max-width: 80px;
  min-width: 80px;
  margin-right: 16px;
}

.member-profile-img img {
  width: 100%;
  display: flex;
  align-items: center;
  border: 2px solid #dddddd;
  border-radius: 50%;
}

.member-profile-text {
  display: block;
  margin-right: 60px;
  max-width: 108px;
  width: 100%;
  text-align: center;
}

.member-profile-text:last-child {
  margin-right: 0;
}

.member-profile-text h4 {
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
}

.member-profile-name {
  max-width: 40vw;
  width: 100%;
}

.member-profile-name h4 {
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
}

@media only screen and (max-width: 576px) {
  .member-profile-text h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
  }
}

.member-profile-text p {
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: #808488;
}

.profile-setting {
  background-color: #edf1f7;
  position: absolute;
  right: 0;
  border-radius: 32px;
  top: 14px;
}

.profile-setting-btn {
  margin: 12px 16px;

  @media (max-width: $mobile-screen) {
    margin: 12px 14px;
  }
}

.btn.btn-nostyle {
  border: 0;
  background-color: transparent;
  padding: 0 !important;
  box-shadow: none;
  margin: 0;
}

.row-container {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, 105px);
  grid-gap: 8px;
}

.row-spacebtwn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mt-0 {
  margin-top: 0 !important;
}

.confirm-modalbtn {
  border: 0;
  background-color: transparent;
  margin-top: 25px !important;
  height: auto;
  width: 100%;
  border-radius: 25px;
}
.setting-buttons-modal {
  border-radius: 24px;
  border: none;
  overflow: hidden;
}
.setting-buttons-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.setting-buttons-container button {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #808488;
  padding-top: 16px;
  padding-bottom: 16px;
}
.btn.follow {
  margin: 0;
}

.revenueVisits {
  flex-direction: row;
  margin-left: 40px;
}

.imgMain {
  display: grid;
  grid-auto-columns: auto;
  grid-template-columns: repeat(auto-fill, 160px);
  column-gap: 8px;
}

@media (max-width: $md-screen) {
  .member-profile-topbar {
    justify-content: space-between;
  }

  .member-profile-topbar-wrapper {
    padding-bottom: 16px;
  }

  .revenueVisits {
    margin-left: auto;
  }

  .imgMain {
    grid-template-columns: repeat(auto-fill, 104px);
  }

  .userNameAlign {
    text-align: left;
  }

  .profile-view-main .subscription-group-heading {
    padding: 23px 0;
  }
}

@media only screen and (max-width: 380px) {
  .member-profile-text h4 {
    font-weight: 600;
    font-size: 15px;
    line-height: 28px;
  }
}
