@import "src/styles/variables";

.inner_content{
  margin: 0 16px;
}

.flex-one {
  flex: 1;

  button {
    @media (max-width: $mobile-screen) {
      width: 100% !important;
    }
  }

}

.flex-one-no-btn {
  flex: 1;
}

.flex-two {
  flex: 2;

  button {
    @media (max-width: $mobile-screen) {
      width: 100% !important;
    }
  }

}
/* Items scroll */
.items-section-scroll {
  /* height: calc(100vh - 356px);
  overflow-y: overlay;
  padding-bottom: 15px; */
}

.items-section-scroll::-webkit-scrollbar {
  width: 5px;
  position: absolute;
  display: none;

}

.items-section-scroll::-webkit-scrollbar-track {
  box-shadow: none;
  background-color: transparent;
}

.items-section-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(221, 221, 221, 0.5);
  outline: none;
  border-radius: 5px;

}

.items-section-scroll:hover::-webkit-scrollbar {
  display: block;
}

.nav-btn-separator {
  height: 16px;
  opacity: 0.3;
  width: 1px;
  background-color: #000000;
}