@import "src/styles/variables";

.wizard-form {
  background: #edf4fe;
  padding: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;
  border-radius: 16px;
}
.wizard-input_wrapper {
  margin-bottom: 16px;
}
.wizard-input_wrapper input {
  margin-bottom: 0;
}
.wizard-input_wrapper label {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #282323;
  margin-bottom: 8px;
}

.wizard-input {
  border: 2px solid transparent;
  padding: 10px 16px;
  border-radius: 6px;
  width: 100%;
  outline: 0;
}
.wizard-checkbox_wrapper p {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  margin-left: 15px;
}
.wizard-checkbox_wrapper p span{
  color: #282323;
}
.wizard-checkbox_wrapper a{
  text-decoration: underline;
}
.wizard-checkbox_wrapper .checkbox-container .checkmark {
  margin-left: 0;
}
.wizard-checkbox_wrapper {
  display: flex;
  margin-bottom: 16px;
  align-items: center;
}
.wizard-checkbox_wrapper.wizard-input_error a {
  color: tomato;
}
.wizard-button {
  grid-column: 2;
}
.react-tel-input input.wizard-phone {
  max-width: 100% !important;
  width: 100% !important;
  border: 0;
  height: 41px;
}
.wizard-input_error label,
.wizard-input_error .wizard-error {
  color: tomato;
}
.wizard-input_error input {
  box-shadow: 0 0 0 1px tomato;
}
@media (max-width: $mobile-screen) {
  .wizard-form {
    display: flex;
    flex-direction: column;
  }
}
