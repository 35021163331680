@import "src/styles/variables";

.confirm-buttons {
  display: flex;
  justify-content: space-between;
}
.confirm-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #383838;
  margin-bottom: 16px;
}
button.btn.confirm-btn-outline-primary {
  color: $blue;
  background-color: transparent;
  background-image: none;
  border: 1px solid $blue;
  box-shadow: none;
  &:hover {
    color: #fff;
    background-color: $blue;
    border-color: $blue;
  }
}
