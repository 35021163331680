.loading-container {
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 630px;
  margin: 0 auto;
}
.loading-container-h-100 {
   justify-content: center;
   align-items: center;
   display: flex;
   min-height: 100%;
   margin: 0 auto;
 }