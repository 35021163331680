.notification {
    position: relative;
    cursor: pointer;
  }

  .notification .badge {
    max-height: 21px;
    max-width: 21px;
    position: absolute;
    bottom: -10px;
    right: -10px;
    border-radius: 50%;
    background: red;
    color: white;
    aspect-ratio : 1 / 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }