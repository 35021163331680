@import "src/styles/variables";

.tab-content {
  background: #ffffff;
  box-shadow: 0 24px 40px rgba(75, 133, 220, 0.16);
  border-radius: 40px;
  padding: 48px 56px;
  position: relative;
  margin-right: 10px;
  margin-left: 10px;
  /* overflow-y: hidden; */
  margin-bottom: 48px;
  min-height: calc(100vh - 350px);

  @media (max-width: $mobile-screen) {
    margin-right: 0;
    margin-left: 0;
    padding: 16px;
    border-radius: 20px;
    min-height: calc(100vh - 220px);
    margin-bottom: 16px;
  }

  .layout-header-btn {
    margin-left: 20px;

    @media (max-width: $mobile-screen) {
      margin-left: auto;
    }
  }

  .mobile-btn-finish {
    padding-top: 24px;

    .switcher-wrapper {
      border: $light-gray-border;
      border-radius: 24px;
      width: 329px;
      height: 44px;
    }
    button {
      margin-top: 24px;
    }
  }
}

/*.tab-content::after {
    content: '';
    position: fixed;
    bottom: 0;
    left: 0;
    height: 64px;
    width: 100%;
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);

    @media (max-width: $mobile-screen) {
        height: 32px;
    }
}*/

.inner-heading {
  padding-bottom: 24px;
  border-bottom: 1px solid #dddddd;
  margin-bottom: 24px;
}

.btn-nostyle {
  border: 0;
  background-color: transparent;
  padding: 0;
}
