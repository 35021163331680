@import "src/styles/variables";

.order-info-header {
  display: flex;
  padding: 16px;
  position: relative;
  justify-content: center;
}
.tab-labels {
  background: #ebf4ff;
  border-radius: 56px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 4px;
}
.tab-label {
  width: 142px;
  height: 71px;
  transition: 0.3s;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 24px;
  gap: 8px;
  border-radius: 40px;
  cursor: pointer;
}
.tab-label p {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.03em;
  color: #808488;
}
.tab-label.active {
  background: #ffffff;
}
.tab-label.active p {
  color: #4b85dc;
}
.order-info_close {
  width: 48px;
  position: absolute;
  right: 16px;
  height: 48px;
  background: #edf1f7;
  border-radius: 24px;
  border: none;
}
@media (max-width: 690px) {
  .tab-labels {
    max-width: 360px;
    width: 100%;
  }
  .tab-label {
    max-width: 106px;
    width: 100%;
    height: 60px;
    padding: 8px;
    align-items: center;
    justify-content: center;
  }
  .tab-label p {
    font-size: 12px;
  }
}
@media (max-width: $mobile-screen) {
  .order-info-header {
    flex-direction: column-reverse;
    align-items: center;
    padding: 8px 0;
  }
  .order-info_close {
    right: 0;
    position: relative;
    margin-bottom: 16px;
  }
}
