.modal-single-product {
    width: 105px;
    height: 105px;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
  }
  
  .check-circle {
    position: absolute;
    height: 35px;
    width: 35px;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
  }

  .counter {
    font-size: 30px;
    padding-left: 15px;
    border-left: 1px solid #e0e0e0;
    width: 50px;
  }