@import "src/styles/variables";

.switcher-wrapper {
  align-items: center;
  justify-content: flex-end;
  p {
    font-weight: 700;
    cursor: pointer;
  }

  .switcher-target {
    margin: auto 10px;
  }

  .switcher-active-p {
    color: $blue;
  }

  .switcher-passive-p {
    color: $grey;
  }
}

@media (max-width: $mobile-screen) {
  .switcher-wrapper {
    justify-content: center;
    margin: 15px auto 2px;
    padding-bottom: 24px;
    margin-bottom: 2px;
    border-bottom: 1px solid #DDDDDD;
  }
}
