.inner-heading .btn {
    box-shadow: none;
}

.inner-heading .btn-nostyle {
    font-weight: 500;
}

.custom-file .custom-file-label {
    width: 116px;
    height: 116px;
    background: rgba(183, 207, 242, 0.15);
    border: 2px dashed #B7CFF2;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.custom-file .custom-file-label::after {
    display: none;
}

.custom-file .custom-file-label img {
    width: auto;
}

.add-product-field {
    padding-bottom: 24px;
    margin-bottom: 2px;
    border-bottom: 1px solid #DDDDDD;
}

.add-product-field label, .add-product-field h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #808488;
    margin-bottom: 16px;
}

.add-product-field h4 {
    margin-bottom: 0 !important;
}

.delete-img-btn {
    background: white;
    border: 0;
    border-radius: 50%;
}

.mb-26 {
    margin-bottom: 26px;
}

.add-product-field .form-control {
    padding: 12px 16px;
    background-color: #ffffff;
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    margin-bottom: 0px;
    align-items: center;
    display: flex;
    height: 42px;
}

.add-product-field p {
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    color: #BDC1C5;
    margin-top: 8px;
}

.add-product-field textarea.form-control {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    resize: none;
    height: 68px;
}

.input-group-append .btn.btn-dropdown {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    padding: 10px 16px !important;
    border-radius: 0px 6px 6px 0px !important;
    box-shadow: none;
}

.input-group-append .btn.btn-dropdown:after {
    display: none;
}

.btn.btn-add-other {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #808488;
    background-color: transparent;
    border: 1px dashed #808488;
    border-radius: 8px !important;
    box-shadow: none;
    padding: 11px 24px !important;
}

.btn.btn-country {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: black;
    background-color: transparent;
    border: 1px solid #808488;
    border-radius: 8px !important;
    box-shadow: none;
    padding: 11px 24px !important;
}

.btn.btn-add-other:hover {
    color: #808488;
}

.btn-add-other img {
    margin-left: 9px;
}

.btn-country img {
    margin-left: 9px;
}

.add-product-field.pick-color {
    padding-bottom: 31px;
}

.add-quantity .form-control {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}

.material-type {
    background-color: transparent;
    border: 1px dashed #BDC1C5;
    border-radius: 4px;
    font-size: 16px !important;
    cursor: pointer;
    padding: 5px 10px;
    width: fit-content;
    margin: 3px 5px 0 0;
    color: #000000 !important;
}

.material-type-selected {
    background-color: #EDF1F7;
    border: 1px dashed #BDC1C5;
    border-radius: 4px;
    font-size: 16px !important;
    cursor: pointer;
    padding: 5px 10px;
    width: fit-content;
    margin: 3px 5px 0 0;
    color: #000000 !important;
}

.mb-6 {
    margin-bottom: 6px !important;
}

.simple-add-button {
    padding: 0;
    border: 0;
    width: fit-content;
    margin: 0;
    background-color: transparent;
    align-items: center;
    display: flex;
    color: #808488;
}

.mb-50 {
    margin-bottom: 50px !important;
}

.border-bottom-0 {
    border-bottom: 0;
}