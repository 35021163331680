.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.rows-select {
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.03em;
  color: #808488;
  display: inline-flex;
  align-items: center;
  margin-bottom: 12px;
}
.rows-select p {
  margin-right: 16px;
}
.rows-select select {
  appearance: none;
  grid-area: select;
  background: transparent;
  border: none;
  font-weight: 600;
  font-size: 12px;
  z-index: 1;
  cursor: pointer;
  letter-spacing: 0.03em;
  color: #808488;
  padding: 5px 12px 5px 5px;
  outline: none;
}
.rows-select select::-ms-expand {
  display: none;
}
.rows-select .select-wrapper {
  display: grid;
  grid-template-areas: "select";
  position: relative;
  cursor: pointer;
}
.select-wrapper::after {
  content: "";
  border-style: solid;
  grid-area: select;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border-width: 4px 4px 0 4px;
  border-color: #808488 transparent transparent transparent;
}

.page-select {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  display: inline-flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.03em;
  color: #808488;
}
.page-select img.disable {
  visibility: hidden;
}
.page-select p {
  margin: 0 20px;
}

.page-select img {
  cursor: pointer;
  user-select: none;
}
