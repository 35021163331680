@import "src/styles/variables";

footer {
  padding: 15px 0;

  .footer-navigation {
    display: flex;
    justify-content: space-between;
    font-size: 15px;

    @media (max-width: $mobile-screen) {
      flex-direction: column;
      justify-content: start;
      margin: 12px 0;
    }

    .footer-navigation-link {
      font-weight: 500;
      color: $grey;
      margin: 0 28px;

      @media (max-width: $mobile-screen) {
        margin: 12px 0;
      }
    }

    .footer-navigation-link:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .payment-social {
    margin-top: 30px;
    // margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    @media (max-width: $mobile-screen) {
      flex-direction: column;
      align-items: flex-start;
    }

    .payment{
      svg {
        margin-right: 16px;
      }

      p {
        color: $blue;
        font-weight: 500;
        margin-bottom: 12px;
      }

      @media (max-width: $mobile-screen) {
        margin-bottom: 24px;
      }
    }


    .social {
      display: flex;
      flex-direction: column;

      @media (max-width: $mobile-screen) {
        margin-top: 24px;
      }

      p {
        color: $grey;
        margin-bottom: 12px;
      }

      .social-items {
        display: flex;
        flex-direction: row;
      }

      .social-item {
          cursor: pointer;
      }
      .social-item:not(:last-child) {
          margin-right: 16px;
      }
    }
  }

  .footer-divider {
    height: 56px;
    width: 1px;
    background-color: #DDDDDD;

    @media (max-width: $mobile-screen) {
      height: 1px;
      width: 100%;
    }
  }

  .footer-copyright{
    font-size: 14.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 24px 0 16px;
    color: $grey;
  }
}

