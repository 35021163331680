@import "src/styles/variables";


.my-orders-header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}


@media (max-width: $tabletDMini-screen) {
  .my-orders-header {
    flex-direction: column;
  }
}

.my-orders-wrapper {
  position: relative;
}

.orders-date {
  display: flex;
  justify-content: center;
  color: $grey;
  font-size: 14px;
  font-weight: 300;
}