@import "src/styles/variables";

.custom-modal {
  background: #ffffff;
  border-radius: 32px;
  max-width: 500px;
  width: fit-content;
  position: fixed;
  z-index: 1005;
  max-height: calc(100% - 200px);
  height: fit-content;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
}

.custom-modal-shadow {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  background-color: black;
  opacity: 0.65;
  z-index: 1004;
}
.custom-modal-content {
  padding: 40px;
}
@media (max-width: $mobile-screen) {
  .custom-modal {
    width: calc(100% - 16px);
  }
  .custom-modal-content{
    padding: 20px;
  }
}
