@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "src/styles/variables";

body {
  font-family: "Outfit", sans-serif !important;
  font-size: 14px;
  margin: 0 auto;
  color: #282323;
  background-color: #ecf4ff !important;
  letter-spacing: 0.01em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

ul {
  margin: 0;
  padding: 0;
}

ul li {
  list-style: none;
}

input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}

.Wrap-1280 {
  max-width: 1280px;
  margin: 0 auto;
  position: relative;
}

.Wrap-1680 {
  max-width: 1680px;
  margin: 0 auto;
  position: relative;
}

.bg-card-area {
  background-color: #ffffff;
  opacity: 0.8;
  max-width: 1328px;
  position: absolute;
  margin-left: -24px;
  margin-right: -24px;
  border-radius: 48px;
  left: 0;
  right: 0;
  bottom: 32px;
  top: 32px;
  z-index: -1;

  @media (max-width: $mobile-screen) {
    display: none;
  }
}

h2 {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
}

h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
}

h4 {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}

.form-control {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  background: #ffffff;
  border-radius: 6px;
  padding: 8px 16px;
  border: 0;
  margin-bottom: 16px;
  height: 41px;
}

.form-control.error {
  border: 1px solid #ff0000;
}

.form-control:focus {
  box-shadow: none;
}

.form-control::-webkit-input-placeholder {
  /* Edge */
  color: #808488;
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #808488;
}

.form-control::placeholder {
  color: #808488;
}

.btn {
  background-color: #4b85dc;
  box-shadow: 0 4px 12px rgba(75, 133, 220, 0.5);
  border-radius: 49px !important;
  padding: 16px 24px !important;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0.03em;
  color: #ffffff;
  margin: 0 auto;
  display: flex;
  text-align: center;
  transition: 0.3s;
  justify-content: center;
}

.btn:hover {
  color: #ffffff;
  background-color: #0069d9;
}

.btn:active {
  transform: translateY(4px);
}

.btn-danger {
  background-color: #dc3545;
  box-shadow: 0 0 12px rgba(200, 35, 51, 0.5);
}

.btn-transparent {
  background-color: transparent;
  box-shadow: 0 0 0 2px #4b85dc;
  color: #4b85dc;
}

.btn-danger:hover {
  background-color: #c82333 !important;
}

.delivery-info .btn {
  margin-top: 16px;
}

.btn-gray {
  padding: 12px 16px !important;
  background-color: #edf1f7;
  box-shadow: none;
}

.btn-gray img {
  opacity: 0.3;
}
