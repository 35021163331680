.mt-20 {
    margin-top: 20px;
}
.mb-10 {
    margin-bottom: 10px;
}
.mb-20 {
    margin-bottom: 20px;
}
.text-align-center {
    text-align: center;
}
.text-align-left {
    text-align: left!important;
}
.text-align-rigth {
    text-align: right!important;
}
.italic {
    font-style: italic;
}
.no-offset-p {
    text-indent: 0!important;
}

.help__wrapper {
    padding: 40px 0;
    h1 {
        text-align: center;
        margin-bottom: 60px;
    }
}

.help__tabs {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 30px;
    button {
        margin: 5px;
    }
}

.help__content {
    h3 {
        text-align: center;
        font-weight: 600;
    }
    h4 {
        font-size: 1.3rem;
        font-weight: 600;
    }
    p, ul, li {
        text-indent: 2em;
        margin-bottom: 10px;
        margin-top: 10px;
    }

    li {
        list-style: inside;
    }
}