@import "src/styles/variables";

.group-add-modal {
  border-radius: 24px;
  border: none;
  overflow: hidden;
}

.tab-headingbar {
  padding-bottom: 32px;
  border-bottom: 1px solid #dddddd;
}

.tab-headingbar h2 {
  line-height: 30px;
}

.subscription-group-header {
  padding: 34px 0 2px;
}

.add-btn {
  background-color: transparent;
  padding: 0;
  border: 0;
}

.product-feed-section {
  padding: 34px 0 0;
}

.product-feed-heading {
  margin-bottom: 26px;
}

.product-feed-products {
  display: grid;
  grid-auto-columns: auto;
  grid-template-columns: repeat(auto-fill, 160px);
  column-gap: 8px;

  @media (max-width: $mobile-screen) {
    grid-template-columns: repeat(auto-fill, 104px);
  }
}

.row-custom {
  margin: 0 -4px;
}

.product-feed-inner {
  /* overflow-y: overlay;
  height: calc(100vh - 562px); */
  position: relative;
  /* padding-bottom: 15px; */
}

.product-feed-inner::-webkit-scrollbar {
  width: 5px;
  position: absolute;
  display: none;
}

.product-feed-inner::-webkit-scrollbar-track {
  box-shadow: none;
  background-color: transparent;
}

.product-feed-inner::-webkit-scrollbar-thumb {
  background-color: rgba(221, 221, 221, 0.5);
  outline: none;
  border-radius: 5px;
}

.product-feed-inner:hover::-webkit-scrollbar {
  display: block;
}

.subscription-group-data {
  border-bottom: 1px solid #dddddd;
  padding-bottom: 24px;
  position: relative;
}

.subscription-group-data-inner {
  padding-bottom: 24px;
  width: 100%;
  position: relative;
  overflow-x: auto;
}

.subscription-group-data::after {
  content: "";
  width: 48px;
  position: absolute;
  right: 0;
  top: 32px;
  height: 114px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 99;
}

.subscription-group-data-inner::-webkit-scrollbar {
  width: 100%;
  height: 4px;
}

.subscription-group-data-inner::-webkit-scrollbar-track {
  box-shadow: none;
  background: #edf1f7;
  border-radius: 4px;
}

.subscription-group-data-inner::-webkit-scrollbar-thumb {
  background: #4b85dc;
  border-radius: 4px;
}

.search-boxinput {
  border: 2px solid #c9c9c9;
  padding: 10px 16px;
  border-radius: 6px;
  margin: 20px 0;
  width: 100%;
  outline: 0;
}

.row-spacebtwn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
}

.subscription-group-img {
  height: 50px;
  margin-right: 10px;
}

.subscription-group-name {
  font-size: 16px;
}

.subscription-group-category {
  color: #a0a0a0;
  font-size: 12px;
}

.hasborder {
  border: 1px solid blue;
}

.modal-save-btn {
  box-shadow: none !important;
  margin-top: 20px !important;
}

.groups-container {
  height: auto;
  overflow-y: scroll;
  max-height: 350px;
  padding-right: 10px;
}

.groups-container::-webkit-scrollbar {
  width: 4px;
}

.groups-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
}

.groups-container::-webkit-scrollbar-thumb {
  background-color: #4b85dc;
  border-radius: 10px;
}

.noGroupsTxt {
  text-align: center;
  margin: 60px;
  font-size: 18px;
  color: gray;
}

.error-text {
  color: red;
  margin-top: 16px;
  text-align: center;
}

.custom-searchbox-margin {
  margin: 50px 0 40px !important;
}

.edit-button {
  padding: 12px 16px;
  background-color: #edf1f7;
  border-radius: 32px;
  transition: 0.2s;
}

.active-button {
  background-color: #4b85dc;
  transition: 0.2s;
}

.edit-group-modal-buttons {
  display: flex;
  gap: 16px;
}

.modal-delete-btn {
  box-shadow: none !important;
  margin-top: 20px !important;
  background: #e44646 !important;
}
